import { Controller } from "@hotwired/stimulus"
import BuildingsSearchController, { Building } from "./buildings_search_controller"
import { createPopupHtml } from "@/src/lib/popupContent"

const CAPITAL_CENTER = [7533, 6226, 14]
const KANSAI_CENTER = [4741, 6462, 14]

/* eslint-disable @typescript-eslint/no-namespace */
declare namespace L {
  interface DivIconOptions {
    className?: string;
    html?: string | false;
    iconSize?: [number, number];
    iconAnchor?: [number, number];
  }

  class DivIcon {
    constructor(_options: DivIconOptions);
    options: DivIconOptions
  }

  function divIcon(_options: DivIconOptions): DivIcon;
}
/* eslint-enable @typescript-eslint/no-namespace */

type Marker = {
  closePopup: () => void
  on: (_event: string, _handler: () => void) => void
}

type Popup = {
  setHTML: (_html: string) => Popup
}

type Station = {
  code: string
}

type Stations = Station[]

declare class Rosen {
  constructor(_element: HTMLElement, _options: {
    apiKey: string
    center: number[]
    zoom: number
    zoomControl: boolean
    tileSetting: string
    apiSetting: string
    uiLanguage: string
  })
  on: (_event: string, _handler: () => void) => void
  clearStationMarkers(): void
  getStationsByCode(_stationCodes: string[]): Promise<Stations>
  setStationMarker(_stationCode: string, _option: { icon: L.DivIcon }): Promise<Marker>
  setView(_center: number[]): void
  static htmlPopup(_options: { closeButton: boolean }): Popup
}

function loadScript(src: string): Promise<void> {
  return new Promise((resolve, reject) => {
    const existingScript = document.querySelector(`script[src="${src}"]`)
    if (existingScript) {
      return resolve()
    }

    const script = document.createElement('script')
    script.src = src
    script.async = true

    script.onload = () => resolve()
    script.onerror = () => reject(`Failed to load script: ${src}`)

    document.head.appendChild(script)
  })
}

export default class extends Controller {
  static values = {
    language: String,
    apiKey: String,
    markerImageUrl: String
  }
  static targets = [
    'map',
    'tab',
    'result',
    'popup'
  ]
  static outlets = [ "buildings-search" ]

  declare readonly mapTarget: HTMLElement
  declare readonly tabTargets: HTMLElement[]
  declare readonly resultTarget: HTMLElement
  declare readonly popupTarget: HTMLElement
  declare readonly hasPopupTarget: boolean
  declare readonly buildingsSearchOutlet: BuildingsSearchController
  declare readonly languageValue: string
  declare readonly apiKeyValue: string
  declare readonly markerImageUrlValue: string
  declare rosen: Rosen

  async connect() {
    await loadScript('https://rmap.ekispert.jp/production/rosen.js')

    if (!this.apiKeyValue) {
      return
    }

    // SEE: https://rmap.ekispert.jp/production/doc/document.html
    this.rosen = new Rosen(this.mapTarget, {
      apiKey: this.apiKeyValue,
      center: CAPITAL_CENTER,
      // consoleViewControl: true, // developmentのときだけ有効にしたい
      zoom: CAPITAL_CENTER[-1],
      zoomControl: false,
      tileSetting: this.languageValue === 'ja' ? 'https' : 'https_en',
      apiSetting: this.languageValue === 'ja' ? 'https' : 'https_en',
      uiLanguage: this.languageValue,
    })
    // NOTE: 地図上をクリックしたらポップアップを閉じるように
    this.rosen.on('selectStation', () => {
      this.popupTarget.innerHTML = ''
    })

    this.buildingsSearchOutlet.search()
  }

  changeCenterCapital(event: Event) {
    this.changeCenter(CAPITAL_CENTER)
    this.activateTab(event)
  }

  changeCenterKansai(event: Event) {
    this.changeCenter(KANSAI_CENTER)
    this.activateTab(event)
  }

  private changeCenter(newCenter: number[]) {
    this.rosen.setView(newCenter)
  }

  private activateTab(event: Event) {
    this.tabTargets.forEach((tab) => {
      tab.classList.remove("active")
    })

    const target = event.currentTarget as HTMLElement
    target.classList.add("active")
  }

  clearMarkers() {
    this.rosen.clearStationMarkers()
  }

  displayMarkers(buildings: Building[]) {
    this.popupTarget.innerHTML = ''

    // NOTE: stationCodeでgroup_byする
    const buildingsByStationCode = buildings.reduce<Record<string, Building[]>>(
      (acc, building) => {
        const key = building.ekispertStationCode
        if (!acc[key]) {
          acc[key] = []
        }
        acc[key].push(building)
        return acc
      },
      {}
    )
    const stationCodes = Object.keys(buildingsByStationCode)

    this.rosen.getStationsByCode(stationCodes).then((stations) => {
      stations.forEach((station: Station) => {
        const stationBuildings = buildingsByStationCode[station.code]
        const countLabel = stationBuildings.length > 1 ? stationBuildings.length : ''

        const size = 40
        const icon = L.divIcon({
          className: 'custom-marker',
          html: `<div class="search__train-line-marker"><img src="${this.markerImageUrlValue}"></img><div class="count">${countLabel}</div></div>`,
          iconSize: [size, size],
          iconAnchor: [size / 2, size],
        })

        this.rosen.setStationMarker(station.code, { icon })
          .then((marker: Marker) => {
            marker.on('click', () => {
              const htmlList = stationBuildings.map((building) => {
                return `<div class="swiper-slide map-popup__slide">${createPopupHtml(building, this.languageValue)}</div>`
              })
              const variant = stationBuildings.length > 1 ? 'multiple' : 'single'

              if (this.hasPopupTarget) {
                this.popupTarget.innerHTML = `
                <div class="swiper slider map-popup__slide ${variant}" data-controller="map-popup" data-map-popup-target="slider">
                  <div class="swiper-wrapper map-popup__wrapper">
                    ${htmlList.join('')}
                  </div>
                  <div class="swiper-button-next map-popup__swipe-button" data-map-popup-target="nextButton" data-action="click->map-popup#next"></div>
                  <div class="swiper-button-prev map-popup__swipe-button" data-map-popup-target="prevButton" data-action="click->map-popup#next"></div>
                </div>
                `
              }
            })
          })
      })
    })
  }

  onSearchCompleted({ detail: { buildings }}: { detail: { buildings: Building[] }}) {
    if (!this.apiKeyValue) {
      return
    }
    this.clearMarkers()
    this.displayMarkers(buildings)
  }
}
