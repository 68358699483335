import { Controller } from "@hotwired/stimulus"
import { Loader } from '@googlemaps/js-api-loader'
import BuildingsSearchController, { Building } from "./buildings_search_controller"
import { createPopupHtml } from "@/src/lib/popupContent"

const CAPITAL_CENTER = { lat: 35.65154346747828, lng: 139.73764198801976 }
const KANSAI_CENTER = { lat: 34.8964499059156, lng: 135.60918496152576 }

declare global {
  interface Window {
    googleMapLanguage: string
  }
}

export default class extends Controller {
  static values = {
    apiKey: String,
    language: String,
    markerImageUrl: String
  }
  static targets = [
    'map',
    'tab',
    'popup',
    'result'
  ]
  static outlets = [ "buildings-search" ]

  declare readonly mapTarget: HTMLElement
  declare readonly tabTargets: HTMLElement[]
  declare readonly popupTarget: HTMLElement
  declare readonly hasPopupTarget: boolean
  declare readonly buildingsSearchOutlet: BuildingsSearchController
  declare readonly apiKeyValue: string
  declare readonly languageValue: string
  declare readonly markerImageUrlValue: string
  declare loader: Loader | null
  declare map: google.maps.Map
  declare markers: google.maps.Marker[]

  // SEE: https://developers.google.com/maps/documentation/javascript?hl=ja
  initialize() {
    // NOTE: 他の言語に切り替えたときリロードが必要なため
    // https://github.com/googlemaps/js-api-loader/issues/210
    if (window.googleMapLanguage && window.googleMapLanguage !== this.languageValue) {
      window.location.reload()
      return
    }
    window.googleMapLanguage = this.languageValue

    this.loader = new Loader({ apiKey: this.apiKeyValue, version: "3.58", language: this.languageValue })
    this.markers = []
  }

  connect() {
    if (!this.apiKeyValue || !this.loader) {
      return
    }
    this.loader.load()
      .then(async(google) => {
        this.map = new google.maps.Map(this.mapTarget, {
          center: CAPITAL_CENTER,
          zoom: 11,
          mapId: 'SEARCH_BUILDINGS_BY_MAP',
          disableDefaultUI: true
        })
        this.buildingsSearchOutlet.search()

        this.map.addListener("click", () => {
          this.popupTarget.innerHTML = ''
        })
      })
      .catch((e) => {
        console.error(e)
      })
  }

  changeCenterCapital(event: Event) {
    this.changeCenter(CAPITAL_CENTER)
    this.activateTab(event)
  }

  changeCenterKansai(event: Event) {
    this.changeCenter(KANSAI_CENTER)
    this.activateTab(event)
  }

  private changeCenter(newCenter: google.maps.LatLngLiteral) {
    this.map.setCenter(newCenter)
    this.map.setZoom(11)
  }

  onSearchCompleted({ detail: { buildings }} : { detail: { buildings: Building[] }}) {
    if (!this.apiKeyValue) {
      return
    }
    this.clearMarkers()
    this.displayMarkers(buildings)
  }

  private clearMarkers() {
    this.markers.forEach((marker) => marker.setMap(null))
    this.markers = []
  }

  private displayMarkers(buildings: Building[]) {
    this.popupTarget.innerHTML = ''
    buildings.forEach((building) => {
      const { latitude, longitude } = building

      if (latitude && longitude) {
        const marker = new google.maps.Marker({
          position: { lat: parseFloat(latitude), lng: parseFloat(longitude) },
          map: this.map,
          icon: {
            url: this.markerImageUrlValue,
            scaledSize: new google.maps.Size(40, 40),
          },
        })

        this.markers.push(marker)

        marker.addListener("click", () => {
          if (this.hasPopupTarget) {
            this.popupTarget.innerHTML = createPopupHtml(building, this.languageValue)
          }
        })
      }
    })
  }

  private activateTab(event: Event) {
    this.tabTargets.forEach((tab) => {
      tab.classList.remove("active")
    })

    const target = event.currentTarget as HTMLElement
    target.classList.add("active")
  }
}
