import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"
import VacancyCheckController from "./vacancy_check_controller"
import CommuteSearchController from "./commute_search_controller"

export type Building = {
  slug: string
  buildingUrl: string
  imageUrl: string
  name: string
  keyPhrase: string
  ekispertStationCode: string
  latitude: string
  longitude: string
  buildingTags: string[]
  minRent: string
}

export default class extends Controller {
  static values = {
    locale: String,
  }
  static targets = [
    'vacanciesCheckbox',
    'minRent',
    'maxRent',
    'waterFacilities',
    'unitCount',
    'buildingTags',
    'withFreeWords',
    'result',
    'conditionCount',
  ]
  static outlets = [
    "vacancy-check",
    "commute-search",
  ]

  declare readonly localeValue: string
  declare readonly minRentTarget: HTMLInputElement
  declare readonly maxRentTarget: HTMLInputElement
  declare readonly waterFacilitiesTargets: HTMLInputElement[]
  declare readonly unitCountTargets: HTMLInputElement[]
  declare readonly buildingTagsTargets: HTMLInputElement[]
  declare readonly withFreeWordsTarget: HTMLInputElement
  declare readonly resultTarget: HTMLElement
  declare readonly conditionCountTarget: HTMLElement
  declare readonly vacancyCheckOutlet: VacancyCheckController
  declare readonly commuteSearchOutlet: CommuteSearchController
  declare readonly hasCommuteSearchOutlet: boolean

  connect() {
    this._updateConditionCount()
    this.minRentRangeChanged()
    this.maxRentRangeChanged()
  }

  minRentRangeChanged() {
    const minRentValue = parseInt(this.minRentTarget.value, 10)
    const maxRentOptions = this.maxRentTarget.querySelectorAll("option")

    maxRentOptions.forEach(option => {
      const optionValue = parseInt(option.value, 10)
      option.disabled = optionValue <= minRentValue
    })
  }

  maxRentRangeChanged() {
    const maxRentValue = parseInt(this.maxRentTarget.value, 10)
    const minRentOptions = this.minRentTarget.querySelectorAll("option")

    minRentOptions.forEach(option => {
      const optionValue = parseInt(option.value, 10)
      option.disabled = optionValue >= maxRentValue
    })
  }

  async fetchBuildings() {
    const urlSearchParams = this.buildQueryParams()

    try {
      const response = await get("/search/search_v2", {
        headers: { "Accept": "application/json" },
        query: urlSearchParams,
        responseKind: "json",
      })

      if (response.ok) {
        const json = await response.json
        return json.buildings
      } else {
        console.error("Request failed")
        return []
      }
    } catch (error) {
      console.error("Error occurred:", error)
      return []
    }
  }

  buildQueryParams() {
    const urlSearchParams = new URLSearchParams()

    if (this.hasCommuteSearchOutlet) {
      const { stationTarget, minutesTarget } = this.commuteSearchOutlet
      urlSearchParams.append("station", stationTarget.value)
      urlSearchParams.append("minutes", minutesTarget.value)
    }

    urlSearchParams.append("with_vacancies", String(this.vacancyCheckOutlet.vacanciesCheckboxTarget.checked))
    urlSearchParams.append("min_rent", this.minRentTarget.value)
    urlSearchParams.append("max_rent", this.maxRentTarget.value)

    this.waterFacilitiesTargets.forEach((checkbox) => {
      if (checkbox.checked) {
        urlSearchParams.append("with_water_facilities[]", checkbox.value)
      }
    })

    this.unitCountTargets.forEach((checkbox) => {
      if (checkbox.checked) {
        urlSearchParams.append("with_unit_counts[]", checkbox.value)
      }
    })

    this.buildingTagsTargets.forEach((checkbox) => {
      if (checkbox.checked) {
        urlSearchParams.append("with_building_tags[]", checkbox.value)
      }
    })

    urlSearchParams.append("free_words", this.withFreeWordsTarget.value)
    urlSearchParams.append("locale", this.localeValue)

    return urlSearchParams
  }

  async search() {
    if (this.hasCommuteSearchOutlet) {
      // NOTE: TurboFrameで更新するので、APIは呼ばない
      return
    }
    const buildings = await this.fetchBuildings()

    this.resultTarget.innerHTML = `${buildings.length}`
    this.dispatch("on-search-completed", { detail: { buildings } })
  }

  async updateCount() {
    this._updateConditionCount()
    const buildings = await this.fetchBuildings()

    this.resultTarget.innerHTML = `${buildings.length}`
    if (this.hasCommuteSearchOutlet) {
      this.commuteSearchOutlet.renderCount(buildings.length)
    }
  }

  private _updateConditionCount() {
    let count = 0
    if (this.minRentTarget.value) { count += 1 }
    if (this.maxRentTarget.value) { count += 1 }
    count += this.waterFacilitiesTargets.filter((checkbox) => checkbox.checked).length
    count += this.unitCountTargets.filter((checkbox) => checkbox.checked).length
    count += this.buildingTagsTargets.filter((checkbox) => checkbox.checked).length
    if (this.withFreeWordsTarget.value) { count += 1 }

    this.conditionCountTarget.classList.toggle("show", count > 0)
    this.conditionCountTarget.innerHTML = `${count}`
  }
}
